
.mapboxgl-popup-close-button {
    top: 2% !important;
    right: 5% !important;
  }
  
  .mapboxgl-popup-content  {
    padding: 10px;
    width: 200px;
    height: 225px;
    border-radius: 15px !important;
    color: white;
    overflow: hidden;
  }
  
  .mapboxgl-popup-content > div {
    width: 100%;
    height: 100%;
  
  }
  
  .popup {
    display: flex;
    flex-direction: column;
    color: black;
    font-size: 14px;
  }
  
  img.post-img {
    max-width: 200px;
}
  
  .post-title {
    font-size: 16px;
    text-decoration: underline;
  }
  
  .description {
    font-size: 14px;
  }
  
  .created-by {
    display: block;
    font-size: 14px;
  }
  
  .date {
    display: block;
    font-size: 12px;
  }
  
  .post-edit-icons svg {
    float: right;
    margin-right: 15px;
    cursor: pointer;
  }
  
  .created-by-div {
    display: block;
  }
  
  .popup input {
    width: 100%;
  }
  
  .popup textarea {
    width: 100%;
    resize: none;
  }
  
  .popup button {
  
    margin-left: 20px;
  }
  