
.button {
  float: right;
  cursor: pointer;
  border: none;
  padding: 8px;
  border-radius: 15px;
  color: white;
}

.btn-post-submit {
  background-color: rgb(114, 121, 250)
}

.register-card {
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.register-form {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.login-card {
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-form {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

