.page-title {
    text-transform: capitalize;
    font-family: 'Pacifico', cursive;
    font-size: 25px;
    margin-left: 20px;
}

.nav-username {
    font-family: 'Lato', sans-serif;
    display: inline-block;
    margin-left: 20px;
}

.btn {
    position: relative;
    border-radius: 50px;
    top: 0px;
    font-size: 16px;
    right: 25px;
    float: right;
    color: white;
    padding: 11px 25px;
    background-color: rgb(108, 189, 108);
}

.login-buttons {
    float: right;
}

.login-buttons .btn {
    margin-right: 20px;
}

.btn-register {
    background-color: rgba(255, 255, 255, 0);
    padding-right: 8px;
}