* {
    box-sizing: border-box;
}
.modal-title {
    display: block;
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 15px; 
}

.modal {
  width: 400px;
  height: 525px;
  background-color: white;
  border-radius: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.modal-login {
    height: 425px;
}

.modal-inner {
    padding: 48px;
}
.modal-label {
    display: block;
    margin-bottom: 10px;
}
.field {
    padding-bottom: 24px;
}
.field input {
    font-size: 16px;
    line-height: 28px;
    padding: 8px 16px;
    width: 100%;
    min-height: 44px;
    border: unset;
    border-radius: 4px;
    outline-color: rgb(84 105 212 / 0.5);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}


input[type="submit"] {
    background-color: rgb(84, 105, 212);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, 
                rgb(84, 105, 212) 0px 0px 0px 1px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
}

.close-modal {
    display: block;

}

.error-msg{
    display: block;

    color: red;
}